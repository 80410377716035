import { IssueRealEstate } from '@arpada/arp-lib-common-qupro';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LogicService {
    public canShowPromoterCommentOnCreate(objWithState: { state: string; }): boolean {
      return objWithState.state && objWithState.state.includes('not_applicable');
    }

    public realEstateHasToReviewTheIssue(issue: IssueRealEstate) {
        return issue.managed_by_property_developer && 'reviewing_promoter' === issue.state_key;
    }

}
