import { Component, OnInit, Input } from '@angular/core';
import { IssueRealEstate } from '@arpada/arp-lib-common-qupro';
import { LogicService } from 'src/app/services/logic.service';

@Component({
  selector: 'issue-compact-line',
  templateUrl: './issue-compact-line.component.html',
  styleUrls: ['./issue-compact-line.component.scss'],
})
export class IssueCompactLineComponent implements OnInit {

  @Input() issue: IssueRealEstate;

  constructor(
    private logicService: LogicService,
  ) { }

  ngOnInit() { }

  isNeededValidation(): boolean {
    return this.logicService.realEstateHasToReviewTheIssue(this.issue);
  }

}
