import { Component, OnInit, Input, EventEmitter, Inject, ViewChild,
         AfterViewInit, OnDestroy } from '@angular/core';
import {
  QuproList, Job, Localization, QuproServiceError,
  Phases, StatesGroups, QuproServiceErrorType, IssuesFilterRealEstate, House
} from '@arpada/arp-lib-common-qupro';
import { ModalController } from '@ionic/angular';
import { ActionsSharedData } from '../actions.shared.data';
import { UiService } from '../../../services/ui.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MessagesService } from '../../../services/messages.service';
import { PortalRealEstateService } from '../../../services/portal-realestate.service';
import { ActionSheetOptions } from '@ionic/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import { IssuePriority } from '../../../services/dto/issue.priority';

import { findReadVarNames } from '@angular/compiler/src/output/output_ast';
import { isGeneratedFile } from '@angular/compiler/src/aot/util';

class ComponentsValues {
  postsaleIsChecked: boolean = null;
  presaleIsChecked: boolean;
  pendingProcessingIsChecked: boolean;
  processedNoDateIsChecked: boolean;
  processedDateIsChecked: boolean;
  pendingMaterialIsChecked: boolean;
  repairedClosedIsChecked: boolean;
  notApplicableIsChecked: boolean;
  localizationSelectId: number;
  jobsSelectIds: number[];
  createDateFrom: string;
  createDateTo: string;
  appointmentDateFrom: string;
  appointmentDateTo: string;
  commonArea: number;
  portal: string;
  floor: string;
  door: string;
  prioritySelected: string;
  idSelected: number;
}

@Component({
  selector: 'app-filter-issues',
  templateUrl: './filter-issues.component.html',
  styleUrls: ['./filter-issues.component.scss'],
})
export class FilterIssuesComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() filter: IssuesFilterRealEstate;
  @Input() sharedData: ActionsSharedData;
  @Input() filterChange = new EventEmitter();

  private loginRedirectSubscription: Subscription;
  componentsValues: ComponentsValues = null;
  jobsArray: Job[] = [];
  localizationsArray: Localization[] = [];
  private housesArray: House[] = [];
  portalArray: string[] = null;
  floorArray: string[] = null;
  doorArray: string[] = null;
  @ViewChild('filterForm', { static : true }) filterForm: NgForm;
  @ViewChild('jobsSelectable', { static: true }) jobsSelectable: IonicSelectableComponent;
  detectChanges = false;
  quproErrorAlertPromise: Promise<HTMLIonAlertElement>;
  readonly customSelectOptions: ActionSheetOptions = { cssClass: 'customSelect', buttons: undefined };
  private detectChangesSubscription: Subscription = null;
  private modalId: string = null;
  isMantenimientoCsite: boolean;
  priorities: IssuePriority[] = [];

  constructor(
    @Inject(ModalController) private modalController: ModalController,
    @Inject(UiService) private uiService: UiService,
    private messages: MessagesService,
    private portalRealEstateService: PortalRealEstateService,
  ) { }

  ngOnInit() {
    this.modalController.getTop().then((top: HTMLIonModalElement) => {
      this.modalId = top.id; // Get modal ID for close it
    });
    this.isMantenimientoCsite = this.sharedData.isMantenimientoCsite;
    this.componentsValues = {
      postsaleIsChecked: this.filter && this.filter.containsPhaseKey(Phases.POSTSALE),
      presaleIsChecked: this.filter && this.filter.containsPhaseKey(Phases.PRESALE),
      pendingProcessingIsChecked: this.filter && this.filter.containsStateGroupKey(StatesGroups.PENDING_PROCESSING),
      processedNoDateIsChecked: this.filter && this.filter.containsStateGroupKey(StatesGroups.PROCESSED_NO_DATE),
      processedDateIsChecked: this.filter && this.filter.containsStateGroupKey(StatesGroups.PROCESSED_DATE),
      pendingMaterialIsChecked: this.filter && this.filter.containsStateGroupKey(StatesGroups.PENDING_MATERIAL),
      repairedClosedIsChecked: this.filter && this.filter.containsStateGroupKey(StatesGroups.REPAIRED_CLOSE),
      notApplicableIsChecked: this.filter && this.filter.containsStateGroupKey(StatesGroups.NOT_APPLICABLE),
      localizationSelectId: this.filter ? this.filter.location_id : null,
      jobsSelectIds: this.filter ? this.filter.getJobsIds() : null,
      createDateFrom: this.filter ? this.getStringOfTimestamp(this.filter.create_date_from) : null,
      createDateTo: this.filter ? this.getStringOfTimestamp(this.filter.create_date_to) : null,
      appointmentDateFrom: this.filter ? this.getStringOfTimestamp(this.filter.appointment_date_from) : null,
      appointmentDateTo: this.filter ? this.getStringOfTimestamp(this.filter.appointment_date_to) : null,
      commonArea: this.filter ? this.filter.common_area : null,
      portal: this.filter ? this.filter.portal : null,
      floor: this.filter ? this.filter.floor : null,
      door: this.filter ? this.filter.letter : null,
      prioritySelected: this.filter ? this.filter.priority : null,
      idSelected: this.filter ? this.filter.id : null
    };
    this.portalRealEstateService.getCachedLocalizations(this.filter.csite_id, null)
    .subscribe((localizationsList: QuproList<Localization>) => {
      this.localizationsArray = localizationsList.elements;
    }, this.onQuproError);
    this.portalRealEstateService.getCachedJobs(this.filter.csite_id).subscribe(
      (jobsList: QuproList<Job>) => {
        this.jobsArray = jobsList.elements;
        this.asignJobsSelectsOrderedToSelectable();
      },
      this.onQuproError
    );
    this.portalRealEstateService.getCachedHouses(this.filter.csite_id, null)
    .subscribe(
      (houseList: QuproList<House>) => {
        this.housesArray = houseList.elements;
      },
      this.onQuproError
    );
    this.priorities = this.portalRealEstateService.getIssuePriorities();
    // Load environments selects if filter is not empty
    if (this.componentsValues) {
      if (this.componentsValues.commonArea === 0) {
        this.loadPortalSelect();
      }
      if (this.componentsValues.portal) {
        this.loadFloorSelect();
      }
      if (this.componentsValues.floor) {
        this.loadDoorSelect();
      }
    }
    // Bind login redirect close
    this.loginRedirectSubscription = this.uiService.subscribeToLoginRedirectEvent( () => {
      this.dismiss();
    });
  }

  ngAfterViewInit(): void {
    /* Use of window.setTimeout because the formControl isnt init.
     * See: https://stackoverflow.com/questions/50286424/error-there-are-no-form-controls-registered-with-this-group-yet-if-youre-usin
     */
    setTimeout(() => {
      this.detectChanges = true;
      this.detectChangesSubscription = this.filterForm.valueChanges.subscribe(this.onChangeComponent);
    });
  }

  ngOnDestroy() {
    if (this.detectChangesSubscription) {
      this.detectChangesSubscription.unsubscribe();
    }
    this.detectChanges = false;
    if (this.loginRedirectSubscription) {
      this.loginRedirectSubscription.unsubscribe();
    }
  }

  onChangeComponent = () => {
    if (this.detectChanges) {
      setTimeout(() => {
        this.updateFiltro();
      }, 50); // Time to apply filters, after change this.filter
    }
  }

  onSelectJobId(eventSelectable: {component: IonicSelectableComponent, isSelected: boolean, item: Job}) {
    if (!this.componentsValues.jobsSelectIds) {
      this.componentsValues.jobsSelectIds = [];
    }
    const index: number = this.componentsValues.jobsSelectIds.indexOf(eventSelectable.item.id);
    if (eventSelectable.isSelected) {
      if (index < 0) {
        this.componentsValues.jobsSelectIds.push(eventSelectable.item.id);
      }
    } else {
      if (index >= 0) {
        this.componentsValues.jobsSelectIds.splice(index, 1);
      }
    }
    setTimeout(() => {
      this.updateFiltro();
    }, 50); // Time to apply filters, after change this.filter
  }

  onChangeJobsSelects() {
    this.asignJobsSelectsOrderedToSelectable();
  }

  clearJobsSelectedItems() {
    this.componentsValues.jobsSelectIds = [];
    this.filter.setJobsIds(null);
    this.jobsSelectable.value = null;
    this.updateFiltro();
  }

  asignJobsSelectsOrderedToSelectable() {
    if (this.componentsValues.jobsSelectIds) {
      const jobsToSelect: Job[] = [];
      for (const jobId of this.componentsValues.jobsSelectIds) {
        const job: Job = this.getJobByJobId(jobId);
        if (job) {
          jobsToSelect.push(job);
        }
      }
      if (jobsToSelect) {
        jobsToSelect.sort((job1, job2) => job1.name.localeCompare(job2.name));
        this.jobsSelectable.value = jobsToSelect;
      }
    }
  }

  getJobByJobId(jobId: number): Job {
    if (jobId >= 0) {
      for (const job of this.jobsArray) {
        if (job && job.id === jobId) {
          return job;
        }
      }
    }
    return null;
  }

  isTotalCountDefined() {
    return this.sharedData && this.sharedData.totalCount
           && this.sharedData.totalCount !== '' && parseInt(this.sharedData.totalCount, 10) >= 0;
  }

  updateFiltro() {
    if ( !this.componentsValues ) { return; }
    this.updateHouseOrCommonArea();
    this.updatePhases();
    this.updateStatesGroups();
    this.updateFeatures();
    this.updateDates();
    this.updateId();
    this.updatePriority();
    this.filterChange.emit();
  }


  clearDateFilter(dateFilterPropertyKey: string) {
    if (this.componentsValues && this.componentsValues[dateFilterPropertyKey]) {
      this.componentsValues[dateFilterPropertyKey] = null;
      this.updateDates();
    }
  }

  private getStringOfTimestamp(timestamp: number): string {
    let toReturn: string = null;
    if (timestamp) {
      toReturn = new Date(timestamp).toISOString();
      // formatDate( timestamp, 'YYYY-MM-DDThh:mm:ss.sTZD', 'es', '+0100');
    }
    return toReturn;
  }

  private onQuproError = (error: QuproServiceError) => {
    if ((!this.quproErrorAlertPromise) && error.type !== QuproServiceErrorType.UNATHORIZED) {
      this.quproErrorAlertPromise = this.uiService.presentAlert(this.messages.getFilterErrorHeader(),
      this.messages.getFilterErrorSubHeader(),
      this.messages.getFilterErrorMessage());
      this.quproErrorAlertPromise.then((alert) => {
        alert.onDidDismiss().finally(() => {
          this.quproErrorAlertPromise = null;
        });
      }).catch(() => {
        this.quproErrorAlertPromise = null;
      });
    }
    this.portalRealEstateService.logger.error('[FilterIssues.onQuproError] ' +
                                               'Error obteniendo los datos necesarios para aplicar los filtros.', error);
  }

  public dismiss() {
    this.detectChanges = false;
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    if (this.jobsSelectable.isOpened) {
      this.jobsSelectable.close();
    }
    this.modalController.dismiss({
      dismissed: true
    }, null, this.modalId);
  }

  private updateHouseOrCommonArea() {
    this.reloadIfIsNeededHouseOrCommonAreaSelectBox();
    this.filter.common_area = this.componentsValues.commonArea;
    this.filter.portal = this.componentsValues.portal;
    this.filter.floor = this.componentsValues.floor;
    this.filter.letter = this.componentsValues.door;
  }

  private reloadIfIsNeededHouseOrCommonAreaSelectBox() {
    if (this.filter.common_area !== this.componentsValues.commonArea) {
      this.reloadPortalSelect();
    } else if (this.filter.portal !== this.componentsValues.portal) {
      this.reloadFloorSelect();
    } else if (this.filter.floor !== this.componentsValues.floor) {
      this.reloadDoorSelect();
    }
  }

  private loadPortalSelect() {
    this.portalArray = [];
    for (const house of this.housesArray) {
      if (house.common_area === this.componentsValues.commonArea &&
          this.portalArray.indexOf(house.portal) < 0) {
        this.portalArray.push(house.portal);
      }
    }
    this.portalArray.sort();
  }

  private reloadPortalSelect() {
    if (this.componentsValues.commonArea === 0) {
      this.loadPortalSelect();
    } else {
      this.portalArray = null;
    }
    this.componentsValues.portal = null;
    this.componentsValues.floor = null;
    this.componentsValues.door = null;
    this.floorArray = null;
    this.doorArray = null;
  }

  private loadFloorSelect() {
    this.floorArray = [];
    for (const house of this.housesArray) {
      if (house.common_area === 0 &&
          house.portal === this.componentsValues.portal &&
          this.floorArray.indexOf(house.floor) < 0) {
        this.floorArray.push(house.floor);
      }
    }
    this.floorArray.sort();
  }

  private reloadFloorSelect() {
    if (this.componentsValues.portal) {
      this.loadFloorSelect();
    } else {
      this.floorArray = null;
    }
    this.componentsValues.floor = null;
    this.componentsValues.door = null;
    this.doorArray = null;
  }

  private loadDoorSelect() {
    this.doorArray = [];
    for (const house of this.housesArray) {
      if (house.common_area === 0 &&
          house.portal === this.componentsValues.portal &&
          house.floor === this.componentsValues.floor &&
          this.doorArray.indexOf(house.letter) < 0) {
        this.doorArray.push(house.letter);
      }
    }
    this.doorArray.sort();
  }

  private reloadDoorSelect() {
    if (this.componentsValues.floor) {
      this.loadDoorSelect();
    } else {
      this.doorArray = null;
    }
    this.componentsValues.door = null;
  }

  private updatePhases() {
    const phaseArray: string[] = [];
    if (this.componentsValues.postsaleIsChecked) {
      phaseArray.push(Phases.POSTSALE);
    }
    if (this.componentsValues.presaleIsChecked) {
      phaseArray.push(Phases.PRESALE);
    }
    this.filter.setPhaseKeys(phaseArray);
  }

  private updateId() {
    this.filter.id = this.componentsValues.idSelected;
  }

  private updatePriority() {
    if (this.componentsValues.prioritySelected !== '') {
      this.filter.priority = this.componentsValues.prioritySelected;
    }else {
      this.filter.priority = null;
    }
  }

  private updateStatesGroups() {
    const stateGroups: string[] = [];
    if (this.componentsValues.pendingProcessingIsChecked) {
      stateGroups.push(StatesGroups.PENDING_PROCESSING);
    }
    if (this.componentsValues.processedNoDateIsChecked) {
      stateGroups.push(StatesGroups.PROCESSED_NO_DATE);
    }
    if (this.componentsValues.processedDateIsChecked) {
      stateGroups.push(StatesGroups.PROCESSED_DATE);
    }
    if (this.componentsValues.pendingMaterialIsChecked) {
      stateGroups.push(StatesGroups.PENDING_MATERIAL);
    }
    if (this.componentsValues.repairedClosedIsChecked) {
      stateGroups.push(StatesGroups.REPAIRED_CLOSE);
    }
    if (this.componentsValues.notApplicableIsChecked) {
      stateGroups.push(StatesGroups.NOT_APPLICABLE);
    }
    this.filter.setStateGroupKeys(stateGroups);
  }

  private updateFeatures() {
    this.filter.location_id = this.componentsValues.localizationSelectId;
    this.filter.setJobsIds(this.componentsValues.jobsSelectIds && this.componentsValues.jobsSelectIds.length > 0
                          ? this.componentsValues.jobsSelectIds : null);
  }

  private updateDates() {
    if (this.componentsValues.createDateFrom) {
      const createDateFrom: Date = new Date(this.componentsValues.createDateFrom);
      createDateFrom.setHours(0, 0, 0, 0);
      this.filter.create_date_from = createDateFrom.getTime();
    } else {
      this.filter.create_date_from = null;
    }
    if (this.componentsValues.createDateTo) {
      const createDateTo: Date = new Date(this.componentsValues.createDateTo);
      createDateTo.setHours(23, 59, 59, 999);
      this.filter.create_date_to = createDateTo.getTime();
    } else {
      this.filter.create_date_to = null;
    }
    if (this.componentsValues.appointmentDateFrom) {
      const appointmentDateFrom: Date = new Date(this.componentsValues.appointmentDateFrom);
      appointmentDateFrom.setHours(0, 0, 0, 0);
      this.filter.appointment_date_from = appointmentDateFrom.getTime();
    } else {
      this.filter.appointment_date_from = null;
    }
    if (this.componentsValues.appointmentDateTo) {
      const appointmentDateTo: Date = new Date(this.componentsValues.appointmentDateTo);
      appointmentDateTo.setHours(23, 59, 59, 999);
      this.filter.appointment_date_to = appointmentDateTo.getTime();
    } else {
      this.filter.appointment_date_to = null;
    }
  }
}
