export class ThemeInfoMapService {
    private static ARPADA_THEME = {theme: 'arpada.theme', logo: 'logo-arpada.png'};
    private static ACTIVITAS_THEME = {theme: 'activitas.theme', logo: 'logo-activitas.png'};
    private static Q21_THEME = {theme: 'q21.theme', logo: 'logo-q21.png'};
    private static AEDAS_THEME = {theme: 'aedashome.theme', logo: 'logo-aedashome.png'};
    private static VIA_CELERE_THEME = {theme: 'viacelere.theme', logo: 'logo-viacelere.png'};

    public static THEME_HOST_MAP = {
        // ARPADA
        'arpada-dev-prom.myvivienda.com': ThemeInfoMapService.ARPADA_THEME,
        'arpada-test-prom.myvivienda.com': ThemeInfoMapService.ARPADA_THEME,
        'arpada-prom.myvivienda.es':  ThemeInfoMapService.ARPADA_THEME,
        'arpada-prom.myvivienda.com':  ThemeInfoMapService.ARPADA_THEME,

        // Activitas
        'activitas-dev-prom.myvivienda.com': ThemeInfoMapService.ACTIVITAS_THEME,
        'activitas-test-prom.myvivienda.com': ThemeInfoMapService.ACTIVITAS_THEME,
        'activitas-prom.myvivienda.com':  ThemeInfoMapService.ACTIVITAS_THEME,
        'activitas-prom.myvivienda.es':  ThemeInfoMapService.ACTIVITAS_THEME,

        // Q21
        'q21-dev-prom.myvivienda.com': ThemeInfoMapService.Q21_THEME,
        'q21-test-prom.myvivienda.com': ThemeInfoMapService.Q21_THEME,
        'q21-prom.myvivienda.com':  ThemeInfoMapService.Q21_THEME,
        'q21-prom.myvivienda.es':  ThemeInfoMapService.Q21_THEME,

        // Aedas
        'aedas-dev-prom.myvivienda.com': ThemeInfoMapService.AEDAS_THEME,
        'aedashome-dev-prom.myvivienda.com': ThemeInfoMapService.AEDAS_THEME,
        'aedas-test-prom.myvivienda.com': ThemeInfoMapService.AEDAS_THEME,
        'aedashome-test-prom.myvivienda.com': ThemeInfoMapService.AEDAS_THEME,
        'aedas-prom.myvivienda.com':  ThemeInfoMapService.AEDAS_THEME,
        'aedas-prom.myvivienda.es':  ThemeInfoMapService.AEDAS_THEME,
        'aedashome-prom.myvivienda.com':  ThemeInfoMapService.AEDAS_THEME,
        'aedashome-prom.myvivienda.es':  ThemeInfoMapService.AEDAS_THEME,

        // Omega40
        'omega40-prom.myvivienda.com':  ThemeInfoMapService.ARPADA_THEME,
        'omega40-prom.myvivienda.es':  ThemeInfoMapService.ARPADA_THEME,

        // Via Celere
        'celere-dev-prom.myvivienda.com': ThemeInfoMapService.VIA_CELERE_THEME,
        'viacelere-dev-prom.myvivienda.com': ThemeInfoMapService.VIA_CELERE_THEME,
        'celere-dev-prom.myvivienda.es': ThemeInfoMapService.VIA_CELERE_THEME,
        'viacelere-dev-prom.myvivienda.es': ThemeInfoMapService.VIA_CELERE_THEME,
        'celere-test-prom.myvivienda.com': ThemeInfoMapService.VIA_CELERE_THEME,
        'viacelere-test-prom.myvivienda.com': ThemeInfoMapService.VIA_CELERE_THEME,
        'celere-test-prom.myvivienda.es': ThemeInfoMapService.VIA_CELERE_THEME,
        'viacelere-test-prom.myvivienda.es': ThemeInfoMapService.VIA_CELERE_THEME,
        '​celere-prom.myvivienda.es': ThemeInfoMapService.VIA_CELERE_THEME,
        'viacelere-prom.myvivienda.es': ThemeInfoMapService.VIA_CELERE_THEME,
        '​celere-prom.myvivienda.com': ThemeInfoMapService.VIA_CELERE_THEME,
        'viacelere-prom.myvivienda.com': ThemeInfoMapService.VIA_CELERE_THEME,
    };

    public static THEME_PARAM_MAP = {
        arpada: ThemeInfoMapService.ARPADA_THEME,
        activitas: ThemeInfoMapService.ACTIVITAS_THEME,
        q21: ThemeInfoMapService.Q21_THEME,
        aedashome: ThemeInfoMapService.AEDAS_THEME,
        aedas: ThemeInfoMapService.AEDAS_THEME,
        viacelere: ThemeInfoMapService.VIA_CELERE_THEME,
        celere: ThemeInfoMapService.VIA_CELERE_THEME,
    };
}
