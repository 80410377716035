import { Component, OnInit, Input, Inject, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UiService } from '../../../services/ui.service';
import { Subscription } from 'rxjs';
import { IssueRealEstate } from '@arpada/arp-lib-common-qupro';

export class UpdateIssueData {
  issue: IssueRealEstate;
  csiteName: string;
  previousAndNextButtons: boolean;
  nextIssue: number;
  previousIssue: number;
}

@Component({
  selector: 'issue-detailed-component',
  templateUrl: './issue-detailed.component.html',
  styleUrls: ['./issue-detailed.component.scss'],
})
export class IssueDetailedComponent implements OnInit, OnDestroy {

  @Input() issue: IssueRealEstate;
  @Input() csiteName: string;
  @Input() previousAndNextButtons: string = null;
  @Input() previousIssue: number = null;
  @Input() nextIssue: number = null;
  @Input() updateIssue: EventEmitter<UpdateIssueData> = new EventEmitter<UpdateIssueData>();
  @Output() changeIssue: EventEmitter<number> = new EventEmitter<number>();

  private loginRedirectSubscription: Subscription;
  showPreviousNextButtons = false;
  isLoading = false;

  constructor(
    @Inject(ModalController) private modalController: ModalController,
    @Inject(UiService) private uiService: UiService,
  ) {
  }

  ngOnInit() {
    // Bind login redirect close
    this.loginRedirectSubscription = this.uiService.subscribeToLoginRedirectEvent( () => {
      this.dismiss();
    });
    if (this.previousAndNextButtons && this.previousAndNextButtons === 'true') {
      this.showPreviousNextButtons = true;
    }
    if (this.updateIssue) {
      this.updateIssue.subscribe((updateData: UpdateIssueData) => {
        this.issue = updateData.issue;
        this.showPreviousNextButtons = updateData.previousAndNextButtons;
        this.nextIssue = updateData.nextIssue;
        this.previousIssue = updateData.previousIssue;
        this.csiteName = updateData.csiteName;
        this.isLoading = false;
      });
    }
  }

  ngOnDestroy() {
    if (this.loginRedirectSubscription) {
      this.loginRedirectSubscription.unsubscribe();
    }
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true
    });
  }

  onChangeIssue(issueNumber: number) {
    if (this.changeIssue) {
      this.isLoading = true;
      this.changeIssue.emit(issueNumber);
    }
  }

}
