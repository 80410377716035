// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL: 'https://api.qupro-odoo10.dev-aks.grupoarpada.com/',
  apiToken: '67F9A1FFBE9837A7D56ADA7CB23D1',
  apiMaxSessionTime: 1000 * 60 * 60 * 23, // 1 day minus 1 hour
  contactAddress: 'desarrollo@grupoarpada.com',
  apiMaxCachedTime: 1000 * 60 * 60 * 10, // 10 hours
  // apiMaxSessionTime: 1000*30, // 30 seconds only for testing proposals
  // maxTotalIssuesExportDownload: 500, // old variable
  maxTotalIssuesPdfExportDownload: 500,
  maxTotalIssuesExcelExportDownload: 10000,
  baseUrl: '/',
  version: '0.0.133', // no tocar para que funcione el autoescalado de version en CI/CD
  resetPasswordUrl: 'https://integration-main-promotor.myvivienda.dev-aks.grupoarpada.com/',
  recaptchaApiKey: '6LcdLNQUAAAAAA4E_4nF_ZxVMCp8c_7DJPctxA8t',
  recaptchaApiKeyAlias: 'property_developer',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
