import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { QuproServiceError, QuproServiceErrorType } from '@arpada/arp-lib-common-qupro';
import { PortalRealEstateService } from '../services/portal-realestate.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanLoad {

  private static firtsLoading = true;

  constructor(
    private router: Router,
    private portalRealEstateService: PortalRealEstateService,
  ) { }

  canLoad(): Observable<boolean> | Promise<boolean> | boolean  {
    try {
      let resolveFun: (value?: boolean) => void = null;
      let rejectFun: (reason?: any) => void = null;
      const promiseToReturn = new Promise<boolean>((resolve, reject) => {
        resolveFun = resolve;
        rejectFun = reject;
      });
      let lastUrl: string = null;
      // Si primera vez que se carga la web
      if (LoginGuard.firtsLoading) {
        // Si la primera ruta que se carga no es el login guardar la ultima URL y redireccionar al loading
        if (this.router.url && this.router.url !== '/login' &&  this.router.url !== 'login') {
          lastUrl = this.router.url;
          this.router.navigate(['loading'], { replaceUrl: true });
        }
        LoginGuard.firtsLoading = false;
      }
      this.portalRealEstateService.isUserLogin(unexpectedError => {
        this.portalRealEstateService.logger.error('[LoginGuard] error inexperado obteniendo si el usuario esta logueado', unexpectedError);
        this.router.navigate(['login'], { replaceUrl: true });
        rejectFun(unexpectedError);
      },
      (quproError: QuproServiceError) => {
        if (!quproError || quproError.type !== QuproServiceErrorType.UNATHORIZED) {
          // Distinto de Unathorized ya que la libreria hace automaticamente el redirect cuando es no autorizado
          this.portalRealEstateService.logger.error(
            '[LoginGuard] error quproServiceError obteniendo si el usuario esta logueado', quproError);
          this.router.navigate(['login'], { replaceUrl: true });
        }
        rejectFun(quproError);
      }).then((result: boolean) => {
        if (lastUrl && result) {
          this.router.navigate([lastUrl], { replaceUrl: true });
        }
        resolveFun(result);
      });
      return promiseToReturn;
    } catch (error) {
      this.portalRealEstateService.logger.error(
        '[LoginGuard] error(Exception) obteniendo si el usuario esta logueado', error);
      this.router.navigate(['login'], { replaceUrl: true });
    }
  }
}
