import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(
    public translateService: TranslateService,
  ) { }

  // Translate methods
  public onInitTranslateService(): Observable<any> {
    return this.translateService.get('language');
  }

  // Qupro messages

  public getLabelFromPhaseKey(phaseKey: string): string {
    let toReturn = this.translateService.instant('general.phases.undefined');
    if (phaseKey) {
      const phaseKeyLowerCase = phaseKey.toLowerCase();
      if (phaseKeyLowerCase === 'postsale' || phaseKeyLowerCase === 'presale' || phaseKeyLowerCase === 'review') {
        toReturn = this.translateService.instant('general.phases.' + phaseKeyLowerCase);
      }
    }
    return toReturn;
  }

  // GENERAL

  public getOkButtonLabel(): string {
    return this.translateService.instant('general.code.ok');
  }

  public getCancelButtonLabel(): string {
    return this.translateService.instant('general.code.cancel');
  }

  public getLoginRedirectAlertHeader(): string {
    return this.translateService.instant('general.code.loginRedirectAlertTitle');
  }

  public getLoginRedirectAlertSubHeader(): string {
    return this.translateService.instant('general.code.loginRedirectAlertSubTitle');
  }

  public getLoginRedirectAlertMessage(): string {
    return this.translateService.instant('general.code.loginRedirectAlertMessage');
  }

  public getConnectionProblemMessage(): string {
    return this.translateService.instant('general.code.connectionProblemMessage', { contactAddress: environment.contactAddress});
  }

  public getUnexpectedErrorMessageWithEmailContact(): string {
    return this.translateService.instant('general.code.unexpectedErrorMessageWithEmailContact',
                                         { contactAddress: environment.contactAddress});
  }

  public getErrorHeaderLabel(): string {
    return this.translateService.instant('general.code.error');
  }

  public getLoadingActionMessage(): string {
    return this.translateService.instant('general.generic.loading');
  }

  public getFileOpenerErrorHeader(): string {
    return this.translateService.instant('general.code.fileOpenerErrorHeader');
  }

  public getFileOpenerErrorMessage(url: string): string {
    return this.translateService.instant('general.code.fileOpenerErrorMessage', {url});
  }

  public getFileSaveErrorHeader(): string {
    return this.translateService.instant('general.code.fileSaveErrorHeader');
  }

  public getFileSaveErrorMessage(): string {
    return this.translateService.instant('general.code.fileSaveErrorMessage');
  }

  public getDownloadUrlErrorHeader(): string {
    return this.translateService.instant('general.code.downloadUrlErrorHeader');
  }

  public getDownloadUrlErrorMessage(): string {
    return this.translateService.instant('general.code.downloadUrlErrorMessage');
  }

  public getDownloadDocumentQuproErrorHeader(): string {
    return this.translateService.instant('general.code.downloadDocumentQuproErrorHeader');
  }

  public getDownloadDocumentQuproErrorMessage(): string {
    return this.translateService.instant('general.code.downloadDocumentQuproErrorMessage');
  }

  public getCanNotLoadCsites(): string {
    return this.translateService.instant('general.code.canNotLoadCsites');
  }

  public getErrorNotMobileNativePlatform(): string {
    return this.translateService.instant('general.code.notMobileNativePlatform');
  }

  public getTimeoutErrorMessage(): string {
    return this.translateService.instant('general.code.timeoutError');
  }

  // Actions issues
  public getActionIssuesCsiteSelectionText(): string {
    return this.translateService.instant('components.actionsIssuesHeader.code.selectCsite');
  }

  public getActionsIssuesCloseText() {
    return this.translateService.instant('general.generic.close');
  }

  // Filter issues component
  public getFilterErrorHeader(): string {
    return this.translateService.instant('components.filterIssues.code.errorHeader');
  }

  public getFilterErrorSubHeader(): string {
    return this.translateService.instant('components.filterIssues.code.errorSubHeader');
  }

  public getFilterErrorMessage(): string {
    return this.translateService.instant('components.filterIssues.code.errorMessage');
  }

  // More actions issues component
  public getMoreActionsActionSheetHeader(): string {
    return this.translateService.instant('components.moreActions.actionsTitle');
  }

  public getMoreActionsIssuesDownloadText(): string {
    return this.translateService.instant('components.moreActions.actions.downloadIssues');
  }

  public getMoreActionsIssuesSummaryDownloadText(): string {
    return this.translateService.instant('components.moreActions.actions.downloadIssuesSummary');
  }

  public getMoreActionsIssuesCloseText(): string {
    return this.translateService.instant('general.generic.close');
  }

  public getErrorExceededTotalToDownloadHeader(): string {
    return this.translateService.instant('components.moreActions.errorExceededTotalToDownloadHeader');
  }

  public getErrorExceededTotalToDownloadMessage(maxTotal: number): string {
    return this.translateService.instant('components.moreActions.errorExceededTotalToDownloadMessage', {maxTotal});
  }

  // LOGIN
  public getLoginReportEmailSubject() {
    return this.translateService.instant('login.code.reportEmailSubject');
  }

  public getEmailValidationMessageOnResetPassword(): string {
    return this.translateService.instant('components.resetPassword.code.emailValidation');
  }

  public getErrorMessageResetPassword(): string {
    return this.translateService.instant('components.resetPassword.code.errorMessage',
                                         { contactAddress: environment.contactAddress});
  }

  public getErrorSubHeaderResetPassword(): string {
    return this.translateService.instant('components.resetPassword.code.errorSubHeader');
  }

  public getErrorHeaderResetPassword(): string {
    return this.translateService.instant('components.resetPassword.code.errorHeader');
  }

  // PASSWORD CHANGE

  public canNotLoginAfterChangePasswordMessage(): string {
    return this.translateService.instant('components.changePassword.code.notLogin', {contactAddress: environment.contactAddress});
  }

  public canNotChangePasswordMessage(): string {
    return this.translateService.instant('components.changePassword.code.errorChangeMessage');
  }

  public getErrorChangePasswordHeader(): string {
    return this.translateService.instant('components.changePassword.code.errorTitle');
  }

  public getSuccessChangePassword(): string {
    return this.translateService.instant('components.changePassword.code.successChange');
  }

  public getUnexpectedErrorTitle(): string {
    return this.translateService.instant('general.code.unexpectedErrorTitle');
  }

  public getUnexpectedChangeLanguageErrorMessage(): string {
    return this.translateService.instant('configuration.code.unexpectedErrorChangeLanguage');
  }

  public getLoginErrorAfterChangePasswordEmptyUsername(): string {
    return this.translateService.instant('components.changePassword.code.loginError');
  }

  public getErrorNewIssueMultipleFilesTitle(): string {
    return this.translateService.instant('newIssue.code.errorTitleMultipleFiles');
  }

  public getCreateIssueErrorTitle(): string {
    return this.translateService.instant('newIssue.code.errorTitle');
  }

  public getUnexpectedErrorMessage(): string {
    return this.translateService.instant('general.code.unexpectedErrorMessage');
  }

  public getCreateIssueSuccessMessage(id: number): string {
    return this.translateService.instant('newIssue.code.createIssueSuccesfully', { id });
  }

  public getErrorTitleCanNotGetCameraPhoto(): string {
    return this.translateService.instant('newIssue.code.cameraErrorTitle');
  }

  public getErrorNewIssueInvalidForm(): string {
    return this.translateService.instant('newIssue.code.invalidForm');
  }

  public getNewIssueExceptionTitle(): string {
    return this.translateService.instant('newIssue.code.createUnexpectedErrorTitle');
  }

  public getCanNotGetImageErrorMessage(): string {
    return this.translateService.instant('newIssue.code.imageError');
  }

  public getErrorNewIssueMultipleFilesMessage(): string {
    return this.translateService.instant('newIssue.code.errorMessageMultipleFiles');
  }

  // Issue Card
  public getErrorMessageDownloadAgreement(): string {
    return this.translateService.instant('components.issueCard.code.errorDownloadAgreement');
  }

  public getCsiteNameNotAvailable(): string {
    return this.translateService.instant('components.issueCard.code.csiteNotAvailable');
  }

  public getNotApplicableHeaderLabel(): string {
    return this.translateService.instant('components.issueCard.code.notApplicableHeader');
  }

  public getNotApplicableSubHeaderLabel(): string {
    return this.translateService.instant('components.issueCard.code.notApplicableSubHeader');
  }

  public getNotApplicableTextInputPlaceHolder(): string {
    return this.translateService.instant('components.issueCard.code.notApplicableTextInputPlaceHolder');
  }

  public getCanNotGetIssueAfterUpdateError(): string {
    return this.translateService.instant('components.issueCard.code.canNotGetUpdatedIssue');
  }
}
