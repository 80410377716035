import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';

import { RecaptchaModule } from 'ng-recaptcha';
import { PipesModule } from '../pipes/pipes.module';
import { CambioPasswordComponent } from './cambio-password/cambio-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PortalModalComponent } from './portal-modal/portal-modal.component';
import { HeaderComponent } from './header/header.component';
import { IssueCompactLineComponent } from './issue-compact-line/issue-compact-line.component';
import { ActionsIssuesHeaderComponent } from './actions-issues-header/actions-issues-header.component';
import { ActionsIssuesMoreActionsComponent } from './actions-issues-header/more-actions-issues/more-actions-issues.component';
import { FilterIssuesComponent } from './actions-issues-header/filter-issues/filter-issues.component';
import { OrderIssuesComponent } from './actions-issues-header/order-issues/order-issues.component';
import { IssueDetailedComponent } from './issue/issue-detailed/issue-detailed.component';
import { IssueCardComponent } from './issue/issue-card/issue-card.component';
import { IssueImageComponent } from './issue/image/issue.image.component';
import { IssueCommentComponent } from './issue/issue-comment/issue-comment.component';
import { ComponentsLibModule } from '@arpada/arp-shared-comp-qupro';
import { IonicSelectableModule } from 'ionic-selectable';

@NgModule({
    declarations: [
      CambioPasswordComponent,
      PortalModalComponent,
      ResetPasswordComponent,
      IssueCompactLineComponent,
      HeaderComponent,
      ActionsIssuesHeaderComponent,
      ActionsIssuesMoreActionsComponent,
      FilterIssuesComponent,
      OrderIssuesComponent,
      IssueDetailedComponent,
      IssueCardComponent,
      IssueImageComponent,
      IssueCommentComponent,
    ],
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
      PipesModule,
      TranslateModule,
      FormsModule,
      RecaptchaModule,
      ComponentsLibModule,
      IonicSelectableModule,
    ],
    exports: [
      CambioPasswordComponent,
      PortalModalComponent,
      ResetPasswordComponent,
      IssueCompactLineComponent,
      HeaderComponent,
      ActionsIssuesHeaderComponent,
    ],
    providers: [
      File,
      FileOpener
    ],
    entryComponents: [
      CambioPasswordComponent,
      PortalModalComponent,
      ResetPasswordComponent,
      FilterIssuesComponent,
      OrderIssuesComponent,
      IssueDetailedComponent,
    ]
  })
  export class ComponentsModule { }
