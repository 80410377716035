import { NgModule, Inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Camera } from '@ionic-native/camera/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginPageModule } from './pages/login/login.module';
import { environment } from '../environments/environment';
import { QuproBaseModule } from '@arpada/arp-lib-common-qupro';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguagesInfo } from '../assets/i18n/languages-info';
import { UiService } from './services/ui.service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { DOCUMENT } from '@angular/common';

let UI_SERVICE: UiService = null;
export function onLoginRedirectCallback()  {
  if (UI_SERVICE) {
    UI_SERVICE.onLoginRedirect();
  }
}
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    LoginPageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
          deps: [HttpClient]
      }
    }),
    DeviceDetectorModule.forRoot(),
    QuproBaseModule.forRoot({
      loginRedirectCallback: onLoginRedirectCallback,
      userResetPassword: '',
      passwordUserResetPassword: '',
      apiURL: environment.apiURL,
      apiToken: environment.apiToken,
      apiMaxSessionTime: environment.apiMaxSessionTime,
      apiMaxCachedTime: environment.apiMaxCachedTime,
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Camera,
    File,
    FileOpener,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AppAvailability,
    InAppBrowser,
  ],
  exports: [
    TranslateModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private translateService: TranslateService,
    private uiService: UiService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    UI_SERVICE = this.uiService;
    LanguagesInfo.initTranslateService(this.translateService, this.document);
  }
}
