import { Component, OnInit, OnDestroy, Input, ContentChild, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UiService } from '../../services/ui.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'portal-modal',
  templateUrl: './portal-modal.component.html',
  styleUrls: ['./portal-modal.component.scss'],
})
export class PortalModalComponent implements OnInit, OnDestroy {

  @Input() bodycolor: string;
  @Input() title: string;
  @Input() showBackButton: string;
  @Input() header: string;
  showBackButtonBoolean = true;
  showHeader = false;

  private loginRedirectSubscription: Subscription;

  constructor(
    private modalController: ModalController,
    private uiService: UiService,
  ) { }

  ngOnInit() {
    // Bind login redirect close
    this.loginRedirectSubscription = this.uiService.subscribeToLoginRedirectEvent( () => {
      this.dismiss();
    });
    if (this.showBackButton && this.showBackButton.toLowerCase() === 'false') {
      this.showBackButtonBoolean = false;
    }
    if (this.header && this.header.toLowerCase() === 'true') {
      this.showHeader = true;
    }
  }

  ngOnDestroy() {
    if (this.loginRedirectSubscription) {
      this.loginRedirectSubscription.unsubscribe();
    }
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true
    });
  }

}
