import { Component, OnInit, Input, Inject } from '@angular/core';
import { File } from '@ionic-native/file/ngx';
import { environment } from '../../../../environments/environment';
import { QuproList, IssuesService, PhotoIssue, IssueRealEstate } from '@arpada/arp-lib-common-qupro';
import { IonCard } from '@ionic/angular';
import { PortalRealEstateService } from '../../../services/portal-realestate.service';

class IssueReaLEstateWithPhoto extends IssueRealEstate {
  photoLocalUrl: string = null;
}

@Component({
  selector: 'issue-image',
  templateUrl: './issue.image.component.html',
  styleUrls: ['./issue.image.component.scss'],
})
export class IssueImageComponent implements OnInit {

  private static WIDTH_IMAGE = 495;
  private static MAP_ISSUE_IMAGES = {};
  readonly NO_PHOTO_URL: string =  environment.baseUrl + 'assets/noPhoto.png';
  readonly LOADING_PHOTO_URL: string = environment.baseUrl + 'assets/loadingPhoto.svg';
  base64ImagePrefix = 'data:image/jpeg;base64,';
  @Input() issuecard: IonCard;
  @Input() issue: IssueReaLEstateWithPhoto;
  baseUrl: string = environment.baseUrl;
  constructor(
    @Inject(File) private fileApi: File,
    private issueService: IssuesService,
    private portalRealEstateService: PortalRealEstateService,
  ) { }

  ngOnInit() {
  }

  ifShowIssuePhoto(): boolean {
    return (this.issue.has_photo && this.issue.photoLocalUrl && this.issue.photoLocalUrl.replace(' ', '') !== '');
  }

  ifShowNoPhoto(): boolean {
    return !this.issue.has_photo;
  }

  ifShowLoadingPhoto(): boolean {
    return (this.issue.has_photo && (!this.issue.photoLocalUrl || this.issue.photoLocalUrl.replace(' ', '') === ''));
  }

  loadImgWhenIsLoadingShowing() {
    this.issueService.getPhotoIssue(this.issue.id,
                                    IssueImageComponent.WIDTH_IMAGE,
                                    IssueImageComponent.WIDTH_IMAGE)
                                    .subscribe(this.getPhoto , this.getPhotoError);
  }

  getPhoto = (photo: QuproList<PhotoIssue>) => {
    if (photo && photo.elements && photo.elements.length > 0 && photo.elements[0] && photo.elements[0].photo) {
      this.issue.photoLocalUrl = this.base64ImagePrefix + photo.elements[0].photo;
    } else {
      this.issue.photoLocalUrl = null;
    }
  }

  getPhotoError = (error: any ) => {
    this.issue.photoLocalUrl = null;
  }
}
