import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { IssuesFilterRealEstate, IssuesOrderRealEstate, CsiteRealEstate, QuproServiceError, QuproList } from '@arpada/arp-lib-common-qupro';
import { ModalController, ActionSheetController } from '@ionic/angular';
import { FilterIssuesComponent } from './filter-issues/filter-issues.component';
import { OrderIssuesComponent } from './order-issues/order-issues.component';
import { ActionsSharedData } from './actions.shared.data';
import { PortalRealEstateService } from '../../services/portal-realestate.service';
import { MessagesService } from '../../services/messages.service';
import { UiService } from 'src/app/services/ui.service';

export { ActionsSharedData } from './actions.shared.data';

@Component({
  selector: 'actions-issues-header',
  templateUrl: './actions-issues-header.component.html',
  styleUrls: ['./actions-issues-header.component.scss'],
})
export class ActionsIssuesHeaderComponent implements OnInit {

  @Input() sharedData: ActionsSharedData;
  @Input() filter: IssuesFilterRealEstate;
  @Input() order: IssuesOrderRealEstate;
  @Input() showCompactListButton: boolean;
  @Input() showCardListButton: boolean;

  @Output() public filterChange = new EventEmitter();
  @Output() public orderChange = new EventEmitter();
  @Output() public compactListPressed = new EventEmitter();
  @Output() public cardListPressed = new EventEmitter();

  selectedCSiteName = null;
  csitesArray: CsiteRealEstate[];
  private csitesQuproLoadedOrError = false;

  constructor(
    @Inject(ModalController) private modalController: ModalController,
    private portalRealEstateService: PortalRealEstateService,
    private actionSheetController: ActionSheetController,
    private uiService: UiService,
    private messagesService: MessagesService,
  ) { }

  ngOnInit() {
    this.portalRealEstateService.getCachedCSitesOfRealEstate().subscribe(
      (csitesList: QuproList<CsiteRealEstate>) => {
        this.csitesArray = csitesList.elements;
        if (this.csitesArray && this.csitesArray.length > 0) {
          for (const csite of this.csitesArray) {
            if (this.filter.csite_id === csite.id) {
              this.selectedCSiteName = csite.name;
              this.sharedData.isMantenimientoCsite = csite.is_csite_mantenimiento;
            }
          }
        } else {
          this.selectedCSiteName = '-';
          this.showCsitesError();
        }
        this.csitesQuproLoadedOrError = true;
      }, (quproError: QuproServiceError) => {
        this.portalRealEstateService.logger.error(
          '[ActionsIssuesHeader.ngOnInit] No se puede obtener el listado de obras.', quproError);
        this.selectedCSiteName = '-';
        this.showCsitesError();
        this.csitesQuproLoadedOrError = true;
      }, () => {
        if ((!this.csitesArray || this.csitesArray.length <= 0) && !this.csitesQuproLoadedOrError) {
          this.portalRealEstateService.logger.error(
            '[ActionsIssues.ngOnInit] Se ha completado la llamada y no hay obras.');
          this.selectedCSiteName = '-';
          this.uiService.presentAlert(this.messagesService.getErrorHeaderLabel(), null, this.messagesService.getTimeoutErrorMessage());
        }
      }
    );
  }

  onCompactListPressed(event: Event) {
    this.compactListPressed.emit(event);
  }

  onCardListPressed(event: Event) {
    this.cardListPressed.emit(event);
  }

  async openFilterModal() {
    const modal = await this.modalController.create({
      component: FilterIssuesComponent,
      componentProps: {
        filter: this.filter,
        filterChange: this.filterChange,
        sharedData: this.sharedData,
      }
    });
    return await modal.present();
  }

  async openOrderModal() {
    const modal = await this.modalController.create({
      component: OrderIssuesComponent,
      componentProps: {
        order: this.order,
        orderChange: this.orderChange,
        sharedData: this.sharedData,
      }
    });
    return await modal.present();
  }

  onChangeCsite(csite: CsiteRealEstate) {
    this.filter.csite_id = csite.id;
    this.selectedCSiteName = csite.name;
    this.sharedData.isMantenimientoCsite = csite.is_csite_mantenimiento;
    if (this.filterChange) {
      this.filterChange.emit();
    }
  }

  async openSelectCsite(event: Event) {
    if (!this.csitesArray || this.csitesArray.length === 0) {
      this.showCsitesError();
      return;
    }
    const buttons = [];
    for (const csite of this.csitesArray) {
      const cssClass = this.filter.csite_id === csite.id ? 'action-sheet-selected' : undefined;
      buttons.push({
        text: csite.name,
        cssClass,
        handler: () => { this.onChangeCsite(csite); },
      });
    }
    buttons.push({
      text: this.messagesService.getActionsIssuesCloseText(),
      icon: 'close',
      role: 'cancel',
    });
    const actionSheetPromise: Promise<HTMLIonActionSheetElement> = this.actionSheetController.create({
      header: this.messagesService.getActionIssuesCsiteSelectionText(),
      buttons
    });
    const actionSheet: HTMLIonActionSheetElement = await actionSheetPromise;
    actionSheet.present();
    return actionSheetPromise;
  }

  private showCsitesError() {
    this.uiService.presentAlert(
      this.messagesService.getErrorHeaderLabel(),
      null,
      this.messagesService.getCanNotLoadCsites());
  }

}
