import { Component, OnInit, Inject } from '@angular/core';

import { Platform, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { PlatformLocation, DOCUMENT } from '@angular/common';
import { ThemeInfo } from './models/themeInfo';
import { ThemeInfoService } from './services/theme-info.service';
import { PortalRealEstateService } from './services/portal-realestate.service';
import { LanguagesInfo } from '..//assets/i18n/languages-info';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private modalController: ModalController,
    private platformLocation: PlatformLocation,
    @Inject(DOCUMENT) private document: Document,
    private realEstateService: PortalRealEstateService,
  ) {
    this.initializeApp();
    this.initBackButtonEvent();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    const currentUsename: string = this.realEstateService.getCurrentLoggedUsername();
    if (currentUsename) {
      LanguagesInfo.loadLanguageByUser(currentUsename);
    }
  }

  async ngOnInit() {
    const themeInfo: ThemeInfo = ThemeInfoService.load();
    if (themeInfo) {
      if (themeInfo.cssText) {
        this.document.documentElement.style.cssText = themeInfo.cssText;
      }
    }
  }

  initBackButtonEvent() {
    this.platform.backButton.subscribe(async () => {
        let closedModal = false;
        try {
          const element = await this.modalController.getTop();
          if (element) {
            element.dismiss();
            closedModal = true;
          }
        } catch (error) {
          this.realEstateService.logger.error('[AppComponent.backButton] Error al obtener el modal top.', error);
        }
        if (!closedModal) {
          this.platformLocation.back();
        }
    });
  }
}
