import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { IssueDetailedComponent } from '../issue-detailed/issue-detailed.component';
import { PortalRealEstateService } from '../../../services/portal-realestate.service';
import {
  QuproServiceError, IssueRealEstate, 
  AgreementDocumentReport, QuproServiceErrorType, IssueUpdateDataValidation
} from '@arpada/arp-lib-common-qupro';
import { UiService } from '../../../services/ui.service';
import { MessagesService } from '../../../services/messages.service';
import { FileDownloadService } from 'src/app/services/file-download.service';
import { LogicService } from 'src/app/services/logic.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'issue-card',
  templateUrl: './issue-card.component.html',
  styleUrls: ['./issue-card.component.scss'],
})
export class IssueCardComponent implements OnInit {

  @Input() issue: IssueRealEstate;
  @Input() allDetails = false;
  @Input() csiteName: string = null;

  processingDownloadAgreement = false;
  processingToReview = false;

  constructor(
    private alertCtrl: AlertController,
    private modalController: ModalController,
    private portalRealEstateService: PortalRealEstateService,
    private uiService: UiService,
    private fileDownloadService: FileDownloadService,
    private logicService: LogicService,
    private messagesService: MessagesService,
    ) { }

  ngOnInit() {
    if (!this.csiteName) {
      this.csiteName = this.messagesService.getCsiteNameNotAvailable();
    }
  }

  async openIssueDetail() {
    if (!this.allDetails) {
      const modal = await this.modalController.create({
        component: IssueDetailedComponent,
        componentProps: {
          issue: this.issue,
          csiteName: this.csiteName,
        }
      });
      return await modal.present();
    }
  }

  hasActions() {
    return this.issue.has_agreement || this.realEstateHasToReviewTheIssue();
  }

  realEstateHasToReviewTheIssue(): boolean {
    return this.logicService.realEstateHasToReviewTheIssue(this.issue);
  }

  issueHasComments(): boolean {
    return (this.issue.owner_comment || this.issue.state_comment || this.issue.postsale_admin_comment
           || this.issue.postsale_technician_comment || this.issue.property_developer_comment) ? true : false;
  }

  processDownloadAgreement() {
    this.processingDownloadAgreement = true;
    this.portalRealEstateService.getAgreementDocumentByIssueId(this.issue.id).subscribe(
      (documentReport: AgreementDocumentReport) => {
        if (documentReport.document && documentReport.document.length > 0) {
          this.fileDownloadService.completeDownloadAction(documentReport.document_name, documentReport.document_mime,
            documentReport.document, 'MoreActionsIssues.downloadIssuesReport').finally(
            () => {
              this.processingDownloadAgreement = false;
            }
          );
        } else {
          this.uiService.presentAlert(
            this.messagesService.getErrorHeaderLabel(),
            null,
            this.messagesService.getErrorMessageDownloadAgreement()
          );
          this.processingDownloadAgreement = false;
        }
      },
      (quporError: QuproServiceError) => {
        this.uiService.showErrorFromServiceErrorOnDownload(quporError, 'MoreActionsIssues.downloadIssuesReport');
        this.processingDownloadAgreement = false;
      }
    );
  }

  processReviewHousingBuilder() {
    this.callToReviewService(true);
  }

  async processNotApplicable() {
    const okButtonLabel: string = this.messagesService.getOkButtonLabel();
    const cancelButtonLabel: string = this.messagesService.getCancelButtonLabel();
    const notConformHeaderLabel: string = this.messagesService.getNotApplicableHeaderLabel();
    const notConformSubHeaderLabel: string = this.messagesService.getNotApplicableSubHeaderLabel();
    const notApplicableTextInputPlaceHolder: string = this.messagesService.getNotApplicableTextInputPlaceHolder();
    const input = await this.alertCtrl.create({
      backdropDismiss: false,
      header: notConformHeaderLabel,
      subHeader: notConformSubHeaderLabel,
      inputs: [
        {
          name: 'txtNotApplicable',
          type: 'text',
          placeholder: notApplicableTextInputPlaceHolder,
        }
      ],
      buttons: [
        {
          text: cancelButtonLabel,
          role: 'cancel',
          handler: () => {
          }
        }, {
          text: okButtonLabel,
          handler: (data) => {
            return this.callToReviewService(false, data.txtNotApplicable);
          }
        }
      ]
    });
    await input.present();
  }

  private callToReviewService(review: boolean, txtNotApplicable?: string) {
    /* Validation: Invalid when not review housingbuilder (state not applicable) and not defined txtNotApplicable
     * txtNotApplicable is needed on not applicable state.
    */
    const correctValidation: boolean = !(!review && !txtNotApplicable);
    if (correctValidation) {
      this.processingToReview = true;
      const issueValidation: IssueUpdateDataValidation = review ? IssueUpdateDataValidation.YES : IssueUpdateDataValidation.NO;
      this.portalRealEstateService.changeReviewHousingBuilderState(this.issue.id, issueValidation, txtNotApplicable).subscribe(
        () => {
          this.updateIssueData().subscribe(() => {
            this.processingToReview = false;
          }, () => {
            this.processingToReview = false;
          });
        },
        (quproServiceError: QuproServiceError) => {
          if (quproServiceError && quproServiceError.type !== QuproServiceErrorType.UNATHORIZED &&
            quproServiceError.type !== QuproServiceErrorType.QUPRO_ERROR) {
            this.portalRealEstateService.logger.error(
              '[IssueCard.callToReviewService] Error cambiando el estado de revision de la incidencia',
              quproServiceError);
            this.uiService.presentAlert(
              this.messagesService.getErrorHeaderLabel(),
              null,
              this.messagesService.getUnexpectedErrorMessage()
            );
          } else if (quproServiceError && quproServiceError.type === QuproServiceErrorType.QUPRO_ERROR) {
            this.uiService.presentAlert(
              this.messagesService.getErrorHeaderLabel(),
              null,
              quproServiceError.message
            );
          }
          this.processingToReview = false;
        },
      );
    }
    return correctValidation;
  }

  updateIssueData(): Observable<IssueRealEstate> {
    const toReturn: Observable<IssueRealEstate> = this.portalRealEstateService.getIssue(this.issue.id);
    toReturn.subscribe(
      (issue: IssueRealEstate) => {
        this.uiService.fireUpdateIssuestEvent([issue]);
      },
      (error: QuproServiceError) => {
        this.portalRealEstateService.logger.error('[IssueCard.updateIssueData] Error al hacer el get despues del update.', error);
        if (error && error.type !== QuproServiceErrorType.UNATHORIZED &&
          error.type !== QuproServiceErrorType.QUPRO_ERROR) {
          this.uiService.presentAlert(
            this.messagesService.getErrorHeaderLabel(),
            this.messagesService.getCanNotGetIssueAfterUpdateError(),
            null);
        } else if (error && error.type === QuproServiceErrorType.QUPRO_ERROR) {
          this.uiService.presentAlert(
            this.messagesService.getErrorHeaderLabel(),
            this.messagesService.getCanNotGetIssueAfterUpdateError(),
            error.message);
        }
      },
    );
    return toReturn;
  }

}
