import { MessagesService } from '../../../services/messages.service';
import { ActionSheetController, LoadingController } from '@ionic/angular';
import { IssuesOrderRealEstate, IssuesFilterRealEstate, IssueOrderRealEstateType,
         QuproServiceError,
         DocumentReport} from '@arpada/arp-lib-common-qupro';
import { PortalRealEstateService } from '../../../services/portal-realestate.service';
import { Component, Input, OnInit } from '@angular/core';
import { ActionsSharedData } from '../actions.shared.data';
import { UiService } from '../../../services/ui.service';
import { FileDownloadService } from '../../../services/file-download.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'more-actions-issues',
    templateUrl: './more-actions-issues.component.html',
    styleUrls: ['./more-actions-issues.component.scss'],
  })
export class ActionsIssuesMoreActionsComponent implements OnInit {

    @Input() filter: IssuesFilterRealEstate = new IssuesFilterRealEstate();
    @Input() order: IssuesOrderRealEstate = new IssuesOrderRealEstate(IssueOrderRealEstateType.DEFAULT_ORDER);
    @Input() sharedData: ActionsSharedData = new ActionsSharedData();

    private loadingWidget: HTMLIonLoadingElement = null;
    private resolveActionSheetActionSelected: (toResolve: boolean) => void;
    private rejectActionSheetActionSelected: (toReject: boolean) => void;
    private readonly actionSheetPromiseExecutor =
        (resolveFun: (toResolve: boolean) => void, rejectFun: (toReject: boolean) => void) => {
            this.resolveActionSheetActionSelected = resolveFun;
            this.rejectActionSheetActionSelected = rejectFun;
        }

    constructor(
        private messagesService: MessagesService,
        private actionSheetController: ActionSheetController,
        private portalRealEstateService: PortalRealEstateService,
        private uiService: UiService,
        private fileDownloadService: FileDownloadService,
        private loadingController: LoadingController,
    ) {}

    async ngOnInit() {
        this.loadingWidget = await this.loadingController.create({
            message: this.messagesService.getLoadingActionMessage(),
            duration: 0,
            backdropDismiss: false,
            showBackdrop: true,
        });
        this.closeLoading();
        this.loadingWidget.present();
    }

    openActions() {
        this.presentActionSheet();
    }

    async presentActionSheet(): Promise<HTMLIonActionSheetElement> {
        const actionSheetPromise: Promise<HTMLIonActionSheetElement> = this.actionSheetController.create({
            header: this.messagesService.getMoreActionsActionSheetHeader(),
            buttons: [
                {
                    text: this.messagesService.getMoreActionsIssuesDownloadText(),
                    cssClass: 'portalActionSheetIcon excel',
                    icon: 'download',
                    handler: this.downloadIssuesReportPressed,
                },
                {
                    text: this.messagesService.getMoreActionsIssuesSummaryDownloadText(),
                    cssClass: 'portalActionSheetIcon pdf',
                    icon: 'download',
                    handler: this.downloadIssuesSummaryPressed,
                },
                {
                    text: this.messagesService.getMoreActionsIssuesCloseText(),
                    icon: 'close',
                    role: 'cancel',
                },
            ]
        });
        const actionSheet: HTMLIonActionSheetElement = await actionSheetPromise;
        actionSheet.present();
        return actionSheetPromise;
    }

    downloadIssuesSummaryPressed = (): Promise<boolean> => {
        if(this.getTotalCountNumber() > environment.maxTotalIssuesPdfExportDownload){
            this.uiService.presentAlert(
                this.messagesService.getErrorExceededTotalToDownloadHeader(),
                null,
                this.messagesService.getErrorExceededTotalToDownloadMessage(environment.maxTotalIssuesPdfExportDownload)
            );
            return;
        }
        this.showLoading();
        this.portalRealEstateService.getIssuesSummaryFileBase64(this.filter, this.order).subscribe((documentReport: DocumentReport) => {
            this.fileDownloadService.completeDownloadAction(documentReport.document_name, documentReport.document_mime,
                                                  documentReport.document, 'MoreActionsIssues.downloadIssuesSummary')
                .then(() => {
                    this.resolveActionSheetActionSelected(true);
                }).catch(() => {
                    this.resolveActionSheetActionSelected(false);
                }).finally(() => {
                    this.closeLoading();
                });
        }, (error: QuproServiceError) => {
            this.uiService.showErrorFromServiceErrorOnDownload(error, 'MoreActionsIssues.downloadIssuesSummary');
            this.resolveActionSheetActionSelected(false);
            this.closeLoading();
        });
        return new Promise<boolean>(this.actionSheetPromiseExecutor);
    }

    downloadIssuesReportPressed = (): Promise<boolean> => {
        if(this.getTotalCountNumber() > environment.maxTotalIssuesExcelExportDownload){
            this.uiService.presentAlert(this.messagesService.getErrorExceededTotalToDownloadHeader(), 
                                        null,
                                        this.messagesService.getErrorExceededTotalToDownloadMessage(environment.maxTotalIssuesExcelExportDownload));
            return;
        }
        this.showLoading();
        this.portalRealEstateService.getIssuesReportFileBase64(this.filter, this.order).subscribe((documentReport: DocumentReport) => {
            this.fileDownloadService.completeDownloadAction(documentReport.document_name, documentReport.document_mime,
                                                  documentReport.document, 'MoreActionsIssues.downloadIssuesReport')
                .then(() => {
                    this.resolveActionSheetActionSelected(true);
                }).catch(() => {
                    this.resolveActionSheetActionSelected(false);
                }).finally(() => {
                    this.closeLoading();
                });
        }, (error: QuproServiceError) => {
            this.uiService.showErrorFromServiceErrorOnDownload(error, 'MoreActionsIssues.downloadIssuesReport');
            this.resolveActionSheetActionSelected(false);
            this.closeLoading();
        });
        return new Promise<boolean>(this.actionSheetPromiseExecutor);
    }

    private getTotalCountNumber(): number {
        let totalCount = null;
        try {
            if(this.sharedData && this.sharedData.totalCount 
                && this.sharedData.totalCount !== '' && parseInt(this.sharedData.totalCount, 10) >= 0){
                    totalCount = parseInt(this.sharedData.totalCount, 10);
            }    
        } catch (error) {
            this.portalRealEstateService.logger.error('[MoreActionsIssues.getTotalCountNumber] Can not parse shared data total count.', error);
        }
        return totalCount;
    }

    private showLoading() {
        this.loadingWidget.hidden = false;
    }

    private closeLoading() {
        this.loadingWidget.hidden = true;
    }
}
