import { Injectable } from '@angular/core';
import { saveAs as fileSaveAs } from 'file-saver';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { MessagesService } from './messages.service';
import { Platform } from '@ionic/angular';
import { LocalStorageQuproLogger } from '@arpada/arp-lib-common-qupro';
import { Constants } from '../models/constants';
import { ShowFileError, ShowFileErrorType } from '../models/show.file.error';
import { UiService } from './ui.service';

@Injectable({
    providedIn: 'root'
})
export class FileDownloadService {

  constructor(
    private uiService: UiService,
    private messagesService: MessagesService,
    private platform: Platform,
    private fileService: File,
    private fileOpener: FileOpener,
    private logger: LocalStorageQuproLogger,
  ) { }


  private getBase64DataHeader(datatype: string): string {
    return 'data:' + datatype + ';base64';
  }

  private convertBase64ToBlob(b64Data: string, contentType: string): Blob {
    contentType = contentType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  private downloadFileOnBrowser(filename: string, datatype: string, base64Data: string) {
    fileSaveAs(this.convertBase64ToBlob(base64Data, datatype), filename);
  }

  private saveAndOpenFile(base64Data: string, filename: string, datatype: string): Promise<any> {
    if (!this.platform.is(Constants.NATIVE_MOBILE_PLATFORM)) { throw new Error('Expected mobile device but was not found.'); }
    return new Promise<any>((resolve: (toResolve: any) => void, reject: (toReject: ShowFileError) => void) => {
      const writeDirectory = this.platform.is(Constants.IOS_PLATFORM) ?
      this.fileService.dataDirectory : this.fileService.externalDataDirectory;
      filename = filename.replace(/:/gi, ''); // TODO: remove this line when filename go fine from server
      filename = filename.replace(/ /gi, '_'); // TODO: remove this line when filename go fine from server
      const fileUri = writeDirectory + filename;
      this.fileService.writeFile(writeDirectory, filename,
        this.convertBase64ToBlob(base64Data, this.getBase64DataHeader(datatype)),
        {replace: true}).then((file: any) => {
          /* Example of file response: {
          "isFile": true,
          "isDirectory": false,
          "name": "informe.txt",
          "fullPath": "/informe.txt",
          "filesystem": "<FileSystem: files-external>",
          "nativeURL": "file:///storage/emulated/0/Android/data/io.ionic.devapp/files/informe.txt"
          } */
          this.fileOpener.open(fileUri, datatype).then((response: any) => {
            resolve(response);
          }).catch((error: Error) => {
            reject(new ShowFileError(error, ShowFileErrorType.FILE_OPENER_ERROR, fileUri));
          });
      }).catch((error: Error) => {
        console.error('Error on save: ', error);
        reject(new ShowFileError(error, ShowFileErrorType.SAVE_ERROR));
      });
    });
  }

  private completeDownloadActionOnMobile(downloadFileName: string, downloadDatatype: string,
                                         downloadBase64: string, loggerName: string): Promise<void> {
    return new Promise((resolve: () => void, reject: () => void) => {
      this.saveAndOpenFile(downloadBase64, downloadFileName, downloadDatatype)
      .then((response: any) => {
        resolve();
      }).catch((error: ShowFileError) => {
        switch (error.type) {
            case ShowFileErrorType.FILE_OPENER_ERROR:
                // Error en ionic devapp -> plugin_not_installed
                this.logger.error('[' + loggerName + '](fileOpener)', error.error);
                this.uiService.presentAlert(this.messagesService.getFileOpenerErrorHeader(),
                                            null, this.messagesService.getFileOpenerErrorMessage(error.fileUri));
                break;
            case ShowFileErrorType.DOWNLOAD_ERROR:
                this.logger.error('[' + loggerName + '](downloadError)', error.error);
                this.uiService.presentAlert(this.messagesService.getDownloadUrlErrorHeader(),
                                            null, this.messagesService.getDownloadUrlErrorMessage());
                break;
            default:
                // SAVE ERROR or other added later error type
                this.logger.error('[' + loggerName + '](' + error.type + ')', error.error);
                this.uiService.presentAlert(this.messagesService.getFileSaveErrorHeader(),
                                            null, this.messagesService.getFileSaveErrorMessage());
                break;
        }
        reject();
      });
    });
  }

  public completeDownloadAction(downloadFileName: string, downloadDatatype: string,
                                downloadBase64: string, loggerName: string): Promise<void> {
    if (this.platform.is(Constants.NATIVE_MOBILE_PLATFORM)) {
        return this.completeDownloadActionOnMobile(downloadFileName, downloadDatatype, downloadBase64, loggerName);
    } else {
        return new Promise((resolve: () => void, reject: () => void) => {
          this.downloadFileOnBrowser(downloadFileName, downloadDatatype, downloadBase64);
          resolve();
        });
    }
  }
}
