import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LocalStorageQuproLogger } from '@arpada/arp-lib-common-qupro';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err =>  { console.error(err), LocalStorageQuproLogger.getLogger().error('[Main.ts]', err); });
