import { Component, OnInit, Input } from '@angular/core';
import { PortalRealEstateService } from '../../services/portal-realestate.service';
import { BaseUser } from '@arpada/arp-lib-common-qupro';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() title: string;
  realEstateBaseUser: BaseUser = new BaseUser();

  constructor(private portalRealEstateService: PortalRealEstateService) { }

  ngOnInit() {
    this.portalRealEstateService.getRealEstateData().subscribe( (resp: BaseUser) => {
      this.realEstateBaseUser = resp;
    });
  }

}
