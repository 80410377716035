import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserService, LoginData, QuproServiceError } from '@arpada/arp-lib-common-qupro';
import { UiService } from '../../services/ui.service';
import { MessagesService } from '../../services/messages.service';
import { PortalRealEstateService } from '../../services/portal-realestate.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-cambio-password',
  templateUrl: './cambio-password.component.html',
  styleUrls: ['./cambio-password.component.scss'],
})
export class CambioPasswordComponent implements OnInit {

  @Input() canCancel: boolean;
  @ViewChild('formulario', { static: true }) formulario: NgForm;

  cambioPassword = {
    newPassword: '',
    confirmNewPassword: ''
  };
  loadingChangePassword = false;

  constructor(private modalCtrl: ModalController,
              private portalRealEstateService: PortalRealEstateService,
              private uiService: UiService,
              private messagesService: MessagesService,
              ) { }

  ngOnInit() {
  }

  onSubmitform() {
    if (this.formulario.invalid) { return; }
    if (this.loadingChangePassword) { return; } // Not multiple submit
    this.loadingChangePassword = true;
    this.portalRealEstateService.changePassword(this.cambioPassword.newPassword).subscribe( resp => {
      try {
        this.portalRealEstateService.loginAfterChangePassword(this.cambioPassword.newPassword)
          .subscribe( (loginData: LoginData) => {
            this.loadingChangePassword = false;
            this.uiService.showSimpleMiddleToast(this.messagesService.getSuccessChangePassword(), 2000);
            this.modalCtrl.dismiss();
          }, (error: QuproServiceError) => {
            this.loadingChangePassword = false;
            this.portalRealEstateService.logger.error(
              '[CambioPasswordComponent] Error (qupro) al intentar hacer login despues del cambio de contraseña.', error);
            const message: string = this.messagesService.canNotLoginAfterChangePasswordMessage();
            this.uiService.presentAlert(this.messagesService.getErrorHeaderLabel(),
                                        this.messagesService.getErrorChangePasswordHeader(), message);
            this.modalCtrl.dismiss();
          });
      } catch (error) {
        this.loadingChangePassword = false;
        this.uiService.presentAlert(this.messagesService.getErrorHeaderLabel(),
                                    this.messagesService.getErrorChangePasswordHeader(), error.message);
        this.portalRealEstateService.logger.error(
          '[CambioPasswordComponent] Error (exception) al intentar hacer login despues del cambio de contraseña.', error);
      }
    }, (error: QuproServiceError) => {
      this.loadingChangePassword = false;
      this.portalRealEstateService.logger.error('[CambioPasswordComponent] Error (qupro) al intentar cambiar de contraseña.', error);
      const message: string = this.messagesService.canNotChangePasswordMessage();
      this.uiService.presentAlert(this.messagesService.getErrorHeaderLabel(),
                                  this.messagesService.getErrorChangePasswordHeader(), message);
      this.modalCtrl.dismiss();
    });
  }

  salir() {
    this.modalCtrl.dismiss();
  }

}
