import {
    QuproService, UserService, LocalStorageQuproLogger, LoginData, QuproServiceError,
    IssuesService, QuproList, IssueRealEstate, IssuesOrderRealEstate, IssuesFilterRealEstate, MastersService,
    StateService, QuproListDefault, State, CsiteService, CsiteBase, IssueCreateDataBase,
    CsiteRealEstate, QuproObservable, DocumentReport, HousesService, House, AgreementDocumentReport,
    AgreementService, IssueUpdateDataRealEstate, IssueUpdateDataValidation, IssueOrderRealEstateType,
    RealEstateUser,
} from '@arpada/arp-lib-common-qupro';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RealEstateExtendedData } from './dto/realEstate.extended.data';
import { MessagesService } from './messages.service';
import { environment } from '../../environments/environment';
import { IssuePriority } from './dto/issue.priority';

@Injectable({
    providedIn: 'root'
})
export class PortalRealEstateService {

    constructor(
        public quproService: QuproService,
        public userService: UserService,
        public issuesService: IssuesService,
        public mastersService: MastersService,
        public csiteService: CsiteService,
        public housesService: HousesService,
        public logger: LocalStorageQuproLogger,
        public houseService: HousesService,
        public agreementService: AgreementService,
        public messagesService: MessagesService,
        public stateService: StateService
    ) {

    }

    public getCurrentLoggedUsername(): string {
        return this.userService.getLoggedUserName();
    }

    public loginUsuario(loginUser: string, password: string): Observable<LoginData> {
       return this.userService.loginUsuario(loginUser, password);
    }

    public logout() {
        return this.userService.logoutUsuario();
    }

    public changePassword(newPassword: string): Observable<string> {
        return this.userService.changePassword(newPassword);
    }

    public loginAfterChangePassword(password: string): Observable<LoginData> {
        const username = this.userService.getLoggedUserName();
        if (username) {
            return this.userService.loginUsuario(username, password);
        } else {
            throw new Error(this.messagesService.getLoginErrorAfterChangePasswordEmptyUsername());
        }
    }

    public secureResetPassword(loginUser: string, resetPasswordUrl: string, captchaResponseToken: string): Observable<object> {
        const captchaAlias: string = environment.recaptchaApiKeyAlias;
        return this.userService.secureResetPassword(loginUser, resetPasswordUrl, captchaAlias, captchaResponseToken);
    }

    public isUserLogin(errorCallback: (error: Error) => void,
                       quproErrorCallback: (error: QuproServiceError) => void): Promise<boolean> {
        return this.userService.isUserLogin(errorCallback, quproErrorCallback);
    }

    public getIssues(filter: IssuesFilterRealEstate, order: IssuesOrderRealEstate,
                     start: number, limit: number): Observable<QuproList<IssueRealEstate>> {
        return this.issuesService.getIssuesRealEstate(filter, order, start, limit);
    }

    public getCachedJobs(csiteId?: number) {
        return this.mastersService.getCachedJobs(csiteId);
    }

    public getCachedLocalizations(csiteId: number, commonArea: number) {
        return this.mastersService.getCachedLocalizations(csiteId, commonArea);
    }

    public getCachedCSitesOfRealEstate(): Observable<QuproList<CsiteRealEstate>> {
        let hasResponse = false;
        const toReturn = new QuproObservable<QuproList<CsiteRealEstate>>();
        const completeTimeoutForMemoryLeaks = 30000;
        this.userService.getLoggedUserDataCached().subscribe(
        (user: RealEstateUser) => {
            this.csiteService.getRealEstateCsitesCaches(user.partner_id).subscribe(
            (csitesList: QuproList<CsiteRealEstate>) => {
                hasResponse = true;
                toReturn.next(csitesList);
                toReturn.completeTimeout(completeTimeoutForMemoryLeaks);
            }, (quproError: QuproServiceError) => {
                hasResponse = true;
                toReturn.error(quproError);
                toReturn.completeTimeout(completeTimeoutForMemoryLeaks);
            });
        },
        (quproError: QuproServiceError) => {
            hasResponse = true;
            toReturn.error(quproError);
            toReturn.completeTimeout(completeTimeoutForMemoryLeaks);
        }, () => {
            if (!hasResponse) {
                toReturn.completeTimeout(completeTimeoutForMemoryLeaks);
            }
        });
        return toReturn;
    }

    public getCachedStatesOfRealEstate(csiteId: number): Observable<QuproListDefault<State>> {
        const toReturn = new QuproObservable<QuproListDefault<State>>();
        const completeTimeoutForMemoryLeaks = 10000;
        this.stateService.getRealEstateStatesCaches(csiteId).subscribe(
            (statesList: QuproListDefault<State>) => {
                toReturn.next(statesList);
                toReturn.completeTimeout(completeTimeoutForMemoryLeaks);
            }, (quproError: QuproServiceError) => {
                toReturn.error(quproError);
                toReturn.completeTimeout(completeTimeoutForMemoryLeaks);
            }
        );
        return toReturn;

    }

    public getCachedHouses(csiteId: number, commonArea: boolean): Observable<QuproList<House>> {
        return this.housesService.getRealEstateHousesCached(csiteId, commonArea);
    }

    public getIssuesReportFileBase64(filter: IssuesFilterRealEstate, order: IssuesOrderRealEstate): Observable<DocumentReport> {
        return this.issuesService.getIssuesReportRealEstate(filter, order);
    }

    public getIssuesSummaryFileBase64(filter: IssuesFilterRealEstate, order: IssuesOrderRealEstate): Observable<DocumentReport> {
        return this.issuesService.getIssuesSummaryRealEstate(filter, order);
    }

    public getAgreementDocumentByIssueId(issueId: number): Observable<AgreementDocumentReport> {
        return this.agreementService.getAgreementByIssueId(issueId);
    }

    public getRealEstateData(): Observable<RealEstateUser> {
        return this.userService.getLoggedUserDataCached<RealEstateUser>();
    }

    public changeLocaleOfUser(selectedLang: string): Observable<string> {
        return this.userService.changeLocaleOfUser(selectedLang);
    }

    public clearAllCaches() {
        this.quproService.clearAllCaches();
    }

    public getPropietarioHouseCached(propietarioId: number): Observable<House> {
        return this.houseService.getOwnerHouseCached(propietarioId);
    }

    public getObraPisoPropietario(obraId: number): Observable<CsiteBase> {
        return this.csiteService.getOwnerCsiteCached(obraId);
    }

    public getPromotorLoggedUserDataNoCache(): Observable<RealEstateUser> {
        return this.userService.getLoggedUserDataNoCache();
    }

    public getRealEstateDataCached(): Observable<RealEstateExtendedData> {
        const toReturn: QuproObservable<RealEstateExtendedData> = new QuproObservable<RealEstateExtendedData>();
        const realEstateExtendedData: RealEstateExtendedData = new RealEstateExtendedData();
        const completeTimeoutForMemoryLeaks = 10000;
        this.userService.getLoggedUserDataCached()
        .subscribe((realEstate: RealEstateUser) => {
          realEstateExtendedData.realEstate = realEstate;
          toReturn.next(realEstateExtendedData);
        },
        (error: QuproServiceError) => {
          toReturn.error(error);
          toReturn.completeTimeout(completeTimeoutForMemoryLeaks);
        });
        return toReturn;
    }

    public createRealEstateIssue(issueToCreate: IssueCreateDataBase): Observable<number> {
        const toReturn: QuproObservable<number> = new QuproObservable<number>();
        let id = -1;
        const completeTimeoutForMemoryLeaks = 10000;
        this.issuesService.createIssue(issueToCreate).subscribe((idCreated: number) => {
            id = idCreated;
            toReturn.next(id);
        }, (error: QuproServiceError) => {
            toReturn.error(error);
            toReturn.completeTimeout(completeTimeoutForMemoryLeaks);
        });
        return toReturn;
    }

    public changeReviewHousingBuilderState(issueId: number,
                                           issueValidation: IssueUpdateDataValidation,
                                           txtNotApplicable?: string): Observable<string> {
        const issueUpdateDataRealEstate: IssueUpdateDataRealEstate = new IssueUpdateDataRealEstate();
        if (txtNotApplicable) {
           issueUpdateDataRealEstate.property_developer_comment = txtNotApplicable;
        }
        issueUpdateDataRealEstate.review_arpada = issueValidation;
        return this.issuesService.updateIssue(issueId, issueUpdateDataRealEstate);
    }

    public getIssue(id: number): Observable<IssueRealEstate> {
        const filter: IssuesFilterRealEstate = new IssuesFilterRealEstate();
        filter.id = id;
        const order: IssuesOrderRealEstate = new IssuesOrderRealEstate(IssueOrderRealEstateType.DEFAULT_ORDER);
        const toReturn: QuproObservable<IssueRealEstate> = new QuproObservable<IssueRealEstate>();
        this.issuesService.getIssuesRealEstate(filter, order, 0, 1).subscribe(
          (quproListIssue: QuproList<IssueRealEstate>) => {
            if (quproListIssue && quproListIssue.elements && quproListIssue.elements.length > 0) {
              toReturn.next(quproListIssue.elements[0]);
            } else {
              toReturn.error(QuproServiceError.getNoDataError());
            }
          },
          (error: QuproServiceError) => {
            toReturn.error(error);
          },
          () => {
            toReturn.complete();
          }
        );
        return toReturn;
    }

    public getIssuePriorities(): IssuePriority[] {
        // TODO que venga del back, no es prioritario ni urgente
        let issuesPriorities: IssuePriority[] = [];
        const prioridades: string[] = ['Todas', 'Urgente', 'Normal'];
        for (let prioridad of prioridades) {
            const priority: IssuePriority = new IssuePriority();
            priority.name = prioridad;
            issuesPriorities.push(priority);
        }
        return issuesPriorities;

    }

}
