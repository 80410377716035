import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginPage } from './pages/login/login.page';
import { TranslateModule } from '@ngx-translate/core';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
  { path: 'loading', loadChildren: './pages/loading/loading.module#LoadingPageModule' },
  { path: 'login', component: LoginPage },
  {
    path: '',
    loadChildren: () => import('./pages/main/main.module').then(m => m.MainPageModule),
    canLoad: [LoginGuard]
  },
  { path: '', pathMatch: 'full', redirectTo: 'main/listado-incidencia'},
  {
    path: 'loading',
    loadChildren: () => import('./pages/loading/loading.module').then( m => m.LoadingPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    TranslateModule,
  ],
  exports: [
    RouterModule,
    TranslateModule,
  ]
})
export class AppRoutingModule {}
