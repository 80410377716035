import { Injectable, Inject } from '@angular/core';
import {
  QuproObservable, LocalStorageQuproLogger, IssueRealEstate, QuproServiceErrorType, QuproServiceError, LogLevel
} from '@arpada/arp-lib-common-qupro';
import { ToastController, AlertController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { MessagesService } from './messages.service';
import { Router } from '@angular/router';
import { Constants } from '../models/constants';
import { saveAs as fileSaveAs } from 'file-saver';
import { ShowFileError, ShowFileErrorType } from '../models/show.file.error';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { environment } from '../../environments/environment';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { InAppBrowser, InAppBrowserObject } from '@ionic-native/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  constructor(@Inject(ToastController) private toastController: ToastController,
              private alertCtrl: AlertController,
              private messagesService: MessagesService,
              private router: Router,
              public logger: LocalStorageQuproLogger,
              private platform: Platform,
              private fileService: File,
              private httpClient: HttpClient,
              private fileOpener: FileOpener,
              private appAvailability: AppAvailability,
              private inAppBrowser: InAppBrowser,
  ) { }

  private static readonly LOGIN_REDIRECT_EVENT: QuproObservable<void> = new QuproObservable<void>();
  public static readonly ISSUE_CREATE_EVENT: QuproObservable<IssueRealEstate[]> = new QuproObservable<IssueRealEstate[]>();
  public static readonly ISSUE_UPDATE_EVENT: QuproObservable<IssueRealEstate[]> = new QuproObservable<IssueRealEstate[]>();

  private loginredirectAlert: HTMLIonAlertElement = null;
  private isShowingLoginRedirectAlert = false;

  public async showSimpleMiddleToast(message: string, duration: number) {
    const toast = await this.toastController.create({
      message,
      duration,
      position: 'middle'
    });
    toast.present();
  }

  public async showSimpleBottomToast(message: string, duration: number) {
    const toast = await this.toastController.create({
      message,
      duration,
      position: 'bottom'
    });
    toast.present();
  }

  private async presentLoginRedirecAlert(): Promise<HTMLIonAlertElement> {
    if (!this.loginredirectAlert && !this.isShowingLoginRedirectAlert) {
      this.isShowingLoginRedirectAlert = true;
      try {
        const okButtonLabel: string = this.messagesService.getOkButtonLabel();
        const header: string = this.messagesService.getLoginRedirectAlertHeader();
        const subHeader: string = this.messagesService.getLoginRedirectAlertSubHeader();
        const message: string = this.messagesService.getLoginRedirectAlertMessage();
        this.loginredirectAlert = await this.alertCtrl.create({
          header,
          subHeader,
          message,
          buttons: [        {
              text: okButtonLabel,
              handler: (blah) => {
                this.loginredirectAlert = null;
                this.isShowingLoginRedirectAlert = false;
              }
            }
          ]
        });
        await this.loginredirectAlert.present();
      } catch (error) {
        this.loginredirectAlert = null;
        this.isShowingLoginRedirectAlert = false;
      }
    }
    return this.loginredirectAlert;
  }

  public onLoginRedirect() {
    this.fireLoginRedirectEvent();
    this.router.navigate(['login'], { replaceUrl: true }).finally(() => {
      this.messagesService.onInitTranslateService().subscribe(
        () => {
          this.presentLoginRedirecAlert();
        },
        () => {
          this.presentAlert('FATAL ERROR',
                            'Unexpected error',
                            'Error when loading translate service please contact with ' + environment.contactAddress + '.');
        }
      );
    });
  }

  public async presentAlert(header: string, subHeader: string, message: string): Promise<HTMLIonAlertElement> {
    const okButtonLabel: string = this.messagesService.getOkButtonLabel();
    const alert: HTMLIonAlertElement = await this.alertCtrl.create({
      header,
      subHeader,
      message,
      buttons: [        {
          text: okButtonLabel,
          handler: (blah) => {
          }
        }
      ]
    });
    await alert.present();
    return alert;
  }


  public showErrorFromServiceErrorOnDownload(error: QuproServiceError, loggerName: string) {
    let loggerMessage = '[' + loggerName + '](QuproError) ' +
                          'Error inexperado al contactar con el servidor.';
    let alertMessage = this.messagesService.getDownloadDocumentQuproErrorMessage();
    switch (error.type) {
        case QuproServiceErrorType.QUPRO_ERROR:
            loggerMessage = '[' + loggerName + '](QuproError) Error de qupro.';
            alertMessage = error.message;
            break;
        case QuproServiceErrorType.CONNECTION_PROBLEM:
            loggerMessage = '[' + loggerName + '](QuproError) Error de conexion.';
            alertMessage = this.messagesService.getConnectionProblemMessage();
            break;
        default:
            break;
    }
    this.logger.error(loggerMessage, error);
    this.presentAlert(this.messagesService.getDownloadDocumentQuproErrorHeader(), null,
                                alertMessage);
  }

  public isPowerBiAppAvailable(): Promise<boolean> {
    return this.isAvailableAppOnNativePlatform(Constants.POWER_BI_APP_ID_IOS_PLATFORM, Constants.POWER_BI_APP_ID_ANDROID_PLATFORM);
  }

  public openPowerBiApp(appId: string): Promise<InAppBrowserObject> {
      return this.launchApp(Constants.POWER_BI_APP_ID_IOS_PLATFORM,
        Constants.POWER_BI_APP_ID_ANDROID_PLATFORM,
        Constants.getAppUrlPowerBi(appId),
        Constants.getWebUrlPowerBi(appId));
  }

  private isAvailableAppOnNativePlatform(iosApp: string, androidApp: string): Promise<boolean> {
    return new Promise((resolve: (result: boolean) => void, reject: (error: any) => void) => {
      let appToCheck: string;
      if (this.platform.is(Constants.IOS_PLATFORM) && this.platform.is(Constants.NATIVE_MOBILE_PLATFORM)) {
        appToCheck = iosApp;
      } else if (this.platform.is(Constants.ANDROID_PLATFORM) && this.platform.is(Constants.NATIVE_MOBILE_PLATFORM)) {
        appToCheck = androidApp;
      }
      if (appToCheck) {
        this.appAvailability.check(appToCheck).then(
          (yes: boolean) => {
            resolve(true);
          },
          (no: boolean) => {
            resolve(false);
          }
        ).catch((error: any) => {
          reject(error);
        });
      } else {
        resolve(false);
      }
    });
  }

  private launchApp(iosApp: string, androidApp: string, appUrl: string, webUrl: string): Promise<InAppBrowserObject> {
    return new Promise((resolve: (browser: InAppBrowserObject) => void, reject: (error: Error) => void) => {
      this.isAvailableAppOnNativePlatform(iosApp, androidApp).then((isAvailable: boolean) => {
        if (isAvailable) {
          // success callback, the app exists and we can open it
          resolve(this.inAppBrowser.create(appUrl, '_system'));
        } else {
          // success callback, the app does not exist, open regular web url instead
          resolve(this.inAppBrowser.create(webUrl, '_system'));
        }
      }).catch((error) => {
        // error callback, the app does not exist, open regular web url instead
        this.inAppBrowser.create(webUrl, '_system');
        reject(error);
      });
    });
  }

  // GLOBAL EVENTS

  public subscribeToLoginRedirectEvent( methodToSubscribe: () => void ): Subscription  {
    return UiService.LOGIN_REDIRECT_EVENT.subscribe( methodToSubscribe );
  }

  public fireLoginRedirectEvent() {
    UiService.LOGIN_REDIRECT_EVENT.next();
  }

  public subscribeCreateIssueEvent( methodToSubscribe: (issues: IssueRealEstate[]) => void ): Subscription {
    return UiService.ISSUE_CREATE_EVENT.subscribe( methodToSubscribe );
  }

  public fireCreateIssuetEvent(issues: IssueRealEstate[]) {
    UiService.ISSUE_CREATE_EVENT.next(issues);
  }

  public subscribeUpdateIssuesEvent( methodToSubscribe: (issues: IssueRealEstate[]) => void ): Subscription  {
    return UiService.ISSUE_UPDATE_EVENT.subscribe( methodToSubscribe );
  }

  public fireUpdateIssuestEvent(issues: IssueRealEstate[]) {
    UiService.ISSUE_UPDATE_EVENT.next(issues);
  }

  public downloadFromURLAction(url: string, filename: string, loggerName: string): Promise<void> {
    if (this.platform.is(Constants.NATIVE_MOBILE_PLATFORM)) {
        return this.completeDownloadFromUrlActionOnMobile(url, filename, loggerName);
    } else {
        return new Promise((resolve: () => void, reject: () => void) => {
          this.downloadFromUrlOnBrowser(url, filename);
          resolve();
        });
    }
  }

  // Private methods

  private completeDownloadFromUrlActionOnMobile(url: string, filename: string, loggerName: string): Promise<void> {
    return new Promise((resolve: () => void, reject: () => void) => {
      this.saveAndOpenUrl(url, filename)
      .then((response: any) => {
        resolve();
      }).catch((error: ShowFileError) => {
        switch (error.type) {
            case ShowFileErrorType.FILE_OPENER_ERROR:
                // Error en ionic devapp -> plugin_not_installed
                this.logger.error('[' + loggerName + '](fileOpener)', error.error);
                this.presentAlert(this.messagesService.getFileOpenerErrorHeader(),
                                            null, this.messagesService.getFileOpenerErrorMessage(error.fileUri));
                break;
            case ShowFileErrorType.DOWNLOAD_ERROR:
                this.logger.error('[' + loggerName + '](downloadError)', error.error);
                this.presentAlert(this.messagesService.getDownloadUrlErrorHeader(),
                                            null, this.messagesService.getDownloadUrlErrorMessage());
                break;
            default:
                // SAVE ERROR or other added later error type
                this.logger.error('[' + loggerName + '](' + error.type + ')', error.error);
                this.presentAlert(this.messagesService.getFileSaveErrorHeader(),
                                            null, this.messagesService.getFileSaveErrorMessage());
                break;
        }
        reject();
      });
    });
  }

  private saveAndOpenUrl(url: string, filename: string): Promise<any> {
    if (!this.platform.is(Constants.NATIVE_MOBILE_PLATFORM)) { throw new Error('Expected mobile device but was not found.'); }
    return new Promise<any>((resolve: (toResolve: any) => void, reject: (toReject: ShowFileError) => void) => {
      const writeDirectory = this.platform.is(Constants.IOS_PLATFORM) ?
      this.fileService.dataDirectory : this.fileService.externalDataDirectory;
      const fileUri = writeDirectory + filename;
      let headers = new HttpHeaders();
      headers = Constants.ADD_HEADERS_FOR_BLOB_FILE(headers);
      this.httpClient.get(url, { headers, responseType: Constants.RESPONSE_TYPE_BLOB }).subscribe((blob: Blob) => {
        this.fileService.writeFile(writeDirectory, filename, blob,
          {replace: true}).then((file: any) => {
            /* Example of file response: {
            "isFile": true,
            "isDirectory": false,
            "name": "informe.txt",
            "fullPath": "/informe.txt",
            "filesystem": "<FileSystem: files-external>",
            "nativeURL": "file:///storage/emulated/0/Android/data/io.ionic.devapp/files/informe.txt"
            } */
            this.fileOpener.open(fileUri, blob.type).then((response: any) => {
              resolve(response);
            }).catch((error: Error) => {
              reject(new ShowFileError(error, ShowFileErrorType.FILE_OPENER_ERROR, fileUri));
            });
        }).catch((error: Error) => {
          reject(new ShowFileError(error, ShowFileErrorType.SAVE_ERROR));
        });
      }, (error: Error) => {
        reject(new ShowFileError(error, ShowFileErrorType.DOWNLOAD_ERROR));
      });
    });
  }

  private downloadFromUrlOnBrowser(url: string, filename: string) {
    fileSaveAs(url, filename);
  }
}
