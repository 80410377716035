import { Phases, StatesGroups } from '@arpada/arp-lib-common-qupro';
import { HttpHeaders } from '@angular/common/http';
export class Constants {

    public static readonly REAL_ESTATE_ALL_PHASES_FILTER = [Phases.POSTSALE, Phases.PRESALE, Phases.MANTENIMIENTO];
    public static readonly REAL_ESTATE_ALL_STATES_GROUPS_FILTER = [StatesGroups.PENDING_PROCESSING,
                                                             StatesGroups.PROCESSED_NO_DATE,
                                                             StatesGroups.PROCESSED_DATE,
                                                             StatesGroups.PENDING_MATERIAL,
                                                             StatesGroups.REPAIRED_CLOSE,
                                                             StatesGroups.NOT_APPLICABLE,
                                                            ];
    public static readonly NATIVE_MOBILE_PLATFORM = 'cordova';
    public static readonly IOS_PLATFORM = 'ios';
    public static readonly ANDROID_PLATFORM = 'android';
    public static readonly RESPONSE_TYPE_BLOB = 'blob';
    public static readonly POWER_BI_APP_ID_IOS_PLATFORM = 'mspbi://app/';
    public static readonly POWER_BI_APP_ID_ANDROID_PLATFORM = 'com.microsoft.powerbim';

    public static getAppUrlPowerBi(appId: string): string {
        return 'mspbi://app/OpenApp?appId=' + appId + '&ctid=a50bd502-8030-42e4-8773-4acd5b7349b0';
    }

    public static getWebUrlPowerBi(appId: string): string {
        return 'https://app.powerbi.com/Redirect?action=OpenApp&appId=' + appId + '&ctid=a50bd502-8030-42e4-8773-4acd5b7349b0';
    }

    public static ADD_HEADERS_FOR_BLOB_FILE(headers: HttpHeaders): HttpHeaders {
        headers.append('Content-Type', 'application/octet-stream');
        headers.append('Content-Disposition', 'attachment');
        return headers;
    }
}
