import { Component, OnInit, Inject, Input, EventEmitter, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UiService } from '../../../services/ui.service';
import { ActionsSharedData } from '../actions.shared.data';
import { IssuesOrderRealEstate, IssueOrderRealEstateType } from '@arpada/arp-lib-common-qupro';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-order-issues',
  templateUrl: './order-issues.component.html',
  styleUrls: ['./order-issues.component.scss'],
})
export class OrderIssuesComponent implements OnInit, OnDestroy {

  public MORE_PRIORITY_ORDER: IssueOrderRealEstateType = IssueOrderRealEstateType.MORE_PRIORITY;
  public MORE_RECENT_ORDER: IssueOrderRealEstateType = IssueOrderRealEstateType.MORE_RECENT;
  public LESS_RECENT_ORDER: IssueOrderRealEstateType = IssueOrderRealEstateType.LESS_RECENT;
  public APPOINTMENT_ASC_ORDER: IssueOrderRealEstateType = IssueOrderRealEstateType.APPOINTMENT_ASC;
  public APPOINTMENT_DESC_ORDER: IssueOrderRealEstateType = IssueOrderRealEstateType.APPOINTMENT_DESC;

  private loginRedirectSubscription: Subscription;

  @Input() order: IssuesOrderRealEstate;
  @Input() sharedData: ActionsSharedData = new ActionsSharedData();
  @Input() orderChange = new EventEmitter();

  constructor(
    @Inject(ModalController) private modalController: ModalController,
    @Inject(UiService) private uiService: UiService,
  ) { }

  ngOnInit() {
    // Bind login redirect close
    this.loginRedirectSubscription = this.uiService.subscribeToLoginRedirectEvent( () => {
      this.dismiss();
    });
  }

  ngOnDestroy() {
    if (this.loginRedirectSubscription) {
      this.loginRedirectSubscription.unsubscribe();
    }
  }

  isTotalCountDefined() {
    return this.sharedData && this.sharedData.totalCount
           && this.sharedData.totalCount !== '' && parseInt(this.sharedData.totalCount, 10) >= 0;
  }

  updateOrder() {
    this.orderChange.emit();
  }

  closeOrderView() {
    this.dismiss();
  }

  public  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true
    });
  }

}
